import { parseSearchState, SearchProvider } from '@faststore/sdk'
import axios from 'axios'
import { useSession } from 'src/sdk/session'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useRef, useState } from 'react'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageView'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import 'src/styles/pages/search.scss'

import { storeUrl } from '../../store.config'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const { locale } = useSession()
  const searchParams = useSearchParams(props.location)
  const title = site?.siteMetadata?.title ?? 'Search Result'
  const { sendpageViewEvent } = usePageViewEvent()
  const viewedOnce = useRef(false)

  const [searchBanners, setSearchBanners] = useState<any>()

  const getSearchBannerData = async ({ body }: any) => {
    await axios
      .post('/api/getSearchBanners', body)
      .then((response) => {
        setSearchBanners(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (
      searchParams?.term ||
      (searchParams?.selectedFacets && searchParams?.selectedFacets.length > 0)
    ) {
      const facets = searchParams?.selectedFacets
        .map((e) => {
          return `/${e.key}/${e.value}`
        })
        .toString()
        .replaceAll(',', '')

      getSearchBannerData({
        body: {
          term: searchParams.term,
          facets,
        },
      })
    }
  }, [searchParams])

  if (!searchParams) {
    return null
  }

  const PageViewEvent = () => {
    if (typeof window !== 'undefined') {
      !viewedOnce.current &&
        sendpageViewEvent({
          location: window?.location?.href ?? '/',
          page: 'Search',
          title,
          originalLocation: storeUrl,
        })

      viewedOnce.current = true
    }

    return <></>
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        noindex
        language={locale}
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <SROnly as="h1" text={title} />
      <PageViewEvent />
      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
      <Breadcrumb name="Todos los productos" />
      <ProductGallery
        title="Resultados de la búsqueda"
        searchTerm={searchParams.term ?? undefined}
        useFilters
        searchBanners={searchBanners}
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)

export { Head } from 'src/components/common/Head'
